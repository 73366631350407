import React from "react"
import { Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import T from "~components/translator"
import { MdHome } from "react-icons/md"

import { breadcrumbItemActive, breadcrumbsWrapper, breadcrumbItem } from "./Breadcrumbs.css"

export const Breadcrumbs = ({ children }) => {
  return (
    <nav className="d-print-none" aria-label="breadcrumb" data-swiftype-index="false">
      <ul className={breadcrumbsWrapper}>{children}</ul>
    </nav>
  )
}

export const BreadcrumbItem = ({ name, url, current }) => {
  return (
    <li className={current ? breadcrumbItemActive : breadcrumbItem} aria-current={current ? "page" : false}>
      {url ? <a href={url}>{name}</a> : name}
    </li>
  )
}

export const ComplexHomeText = () => {
  return (
    <>
      <span className="d-none d-sm-inline">
        <T>wellnav-home</T>
      </span>
      <span className="d-sm-none">
        <MdHome />
      </span>
    </>
  )
}
export const BreadcrumbsGuides = () => {
  let page = React.useContext(FrontmatterPageContext)
  let crumb2text = <T>wellnav-guides</T>
  if ("crumb2override" in page) {
    crumb2text = page["crumb2override"]
  }

  //default
  let crumb3text = "📄"
  let crumb3url = false
  if ("section_name" in page) {
    // yes local override?
    crumb3text = page["section_name"]
    crumb3url = page["section_url"] + page["primary_nav"]
  } else if ("myPrimary" in page) {
    //no local override, get it out of primary data
    crumb3text = page["myPrimary"]["frontmatter"]["section_name"]
    crumb3url = page["section_url"] + page["primary_nav"]
  }

  if ("crumb3urloverride" in page) {
    crumb3url = page["crumb3urloverride"] + page["primary_nav"]
  }

  return (
    <Breadcrumbs>
      <BreadcrumbItem name={<ComplexHomeText />} url={page["home_url"]} />
      {"crumb3urloverride" in page && page.section_url.includes("guides") ? <BreadcrumbItem name={<T>wellnav-guides</T>} url={page["crumb3urloverride"]} />: null }
      {"section" in page && page.section === "ngwaf" ? <BreadcrumbItem name={<T>wellnav-guides</T>} url={page["guides_url"]} />: null }
      {"section" in page && page.section === "ngwaf" ? <BreadcrumbItem name={<T>wellnav-security</T>} url={page["security_url"]} />: null }
      <BreadcrumbItem name={crumb2text} url={page["section_url"]} />
      <BreadcrumbItem name={crumb3text} url={crumb3url} current />
    </Breadcrumbs>
  )
}

export const BreadcrumbsPrimary = () => {
  let page = React.useContext(FrontmatterPageContext)
  let crumb2url = page["section_url"]

  let crumb2text = <T>wellnav-guides</T>

  if ("section" in page && page.section === "ngwaf") {
    crumb2text = <T>wellnav-ngwaf</T>
  }

  if ("crumb2override" in page) {
    crumb2text = page["crumb2override"]
  }

  if ("crumb2urloverride" in page) {
    crumb2url = page["crumb2urloverride"]
  }

  return (
    <Breadcrumbs>
      <BreadcrumbItem name={<ComplexHomeText />} url={page["home_url"]} />
      {page.section_url.includes("guides") || page.section_url.includes("ngwaf") ? <BreadcrumbItem name={<T>wellnav-guides</T>} url={page["guides_url"]} />: null }
      {"section" in page && page.section === "ngwaf" ? <BreadcrumbItem name={<T>wellnav-security</T>} url={page["security_url"]} />: null }
      <BreadcrumbItem name={crumb2text} url={crumb2url} />
    </Breadcrumbs>
  )
}
